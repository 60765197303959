





























































































import Vue from "vue";
import { mapState } from "vuex";
import { dispatch } from "@/store";

export default Vue.extend({
  data() {
    return {
      winVisible: false,
      alertVisible: false,
      receiveVisible: false,
      code: "",
      phone: "",
      email: "",
      nickname: "",
      prize: "",
      isOtherRedeem: false
    };
  },
  computed: {
    ...mapState({ user: "user" }),
    ...mapState("app", {
      isMobile: "isMobile",
      isCN: "isCN"
    }),
    ...mapState("assets", {
      oss: "oss",
      url: "url"
    }),
    product(): any {
      switch (this.prize) {
        case "AM AFA":
          return {
            img: this.oss.draw_am_afa,
            name: this.$t("draw.AMAfa.name"),
            info: this.$t("draw.AMAfa.info"),
            time: this.$t("draw.AMAfa.time")
          };
        case "AM 65 Less":
        case "AM Compact Touch":
        case "AM Compact Touch(65 Less)":
        case "AM Compact Touch（65 Less）":
          return {
            img: this.oss.draw_am_65_less,
            name: this.$t("draw.AM65Less.name"),
            info: this.$t("draw.AM65Less.info"),
            time: this.$t("draw.AM65Less.time")
          };
        case "CYBERBOARD R4 Vision 幻视（渐变条纹）成品套装":
          return {
            img: this.oss.draw_cyberboard_r4_vision,
            name: this.$t("draw.CBR4Vision.name"),
            info: this.$t("draw.CBR4Vision.info"),
            time: this.$t("draw.CBR4Vision.time")
          };
        default:
          return {
            img: this.oss.draw_next,
            name: this.$t("draw.next.name"),
            info: this.$t("draw.next.info"),
            time: this.$t("draw.next.time")
          };
      }
    }
  },
  methods: {
    onSearch() {
      if (!this.code.trim()) {
        this.$message.info("" + this.$t("inputError", [this.$t("draw.code")]));
        return;
      }
      dispatch.draw(this.code).then(res => {
        if (res.is_win) {
          this.winVisible = true;
          this.alertVisible = false;
          this.receiveVisible = false;
          this.nickname = res.nickname;
          this.phone = res.phone;
          this.email = res.email;
          this.prize = res.prize_type;
          this.isOtherRedeem = res.is_other_redeem;
        } else {
          this.winVisible = false;
          this.alertVisible = true;
          this.receiveVisible = false;
        }
      });
    },
    onBack() {
      this.winVisible = false;
      this.alertVisible = false;
      this.receiveVisible = false;
      this.code = "";
    },
    onReceive() {
      this.winVisible = false;
      this.alertVisible = false;
      this.receiveVisible = true;
    },
    onSubmit() {
      if (!this.nickname.trim()) {
        this.$message.info("" + this.$t("inputError", [this.$t("linkman")]));
        return;
      }
      if (this.isCN) {
        const mobileRe = /^1(3\d|4[5-9]|5[0-35-9]|6[567]|7[0-8]|8\d|9[0-35-9])\d{8}$/;
        if (this.isCN && !mobileRe.test(this.phone)) {
          this.$message.info("" + this.$t("inputError", [this.$t("mobile")]));
          return;
        }
      } else {
        const emailRe = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
        if (!emailRe.test(this.email)) {
          this.$message.info("" + this.$t("inputError", [this.$t("email")]));
          return;
        }
      }
      dispatch
        .drawPost({
          code: this.code,
          nickname: this.nickname,
          phone: this.phone,
          email: this.email
        })
        .then(() => {
          this.$message.info(this.$t("draw.submitSuccessfully") + "");
          setTimeout(this.onBack, 2000);
        });
    }
  },
  mounted() {
    if (!this.user.isSignIn) {
      dispatch.userSignIn();
      return;
    }
  }
});
